@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ProtestRiot';
  src: url('./assets/ProtestRiot.ttf') format('truetype');
}

@font-face {
  font-family: 'Afacad';
  src: url('./assets/Afacad.ttf') format('truetype');
}

body {
  font-family: 'Afacad';
}